// extendTheme.js
const lightColorScheme = {
  typography: {
    fontFamily: "SF PRO DISPLAY",
    h1: {
      color: "#FF6600",
    },
    h7: {
      fontWeight: 600,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#FFF",
          boxShadow: "0px 2px 4px -1px #f2f2f2, 0px 4px 5px 0px #f2f2f2, 0px 1px 10px 0px #f2f2f2;",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          textTransform: "none",
          letterSpacing: 1.2,
          // ... other styles
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 50, // Set your desired border radius value here
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        // Name of the slot
        root: {
          borderRadius: 10,
          boxShadow: "0px 2px 4px -1px #f2f2f2, 0px 4px 5px 0px #f2f2f2, 0px 1px 10px 0px #f2f2f2;",
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#FF6600",
    },
    secondary: {
      main: "#000099",
    },
    inactive_text: {
      main: "#BFD4E4",
    },
    notactive_text: {
      main: "#B5B7C0",
    },
    table_text: {
      main: "#444A6D",
    },
    table_border:{
      main: "#DDEAF3"
    },
    thead_bg: {
      main: "#F2F7F9",
    },
    boxBg: {
      main: "#F3F6F9",
    },
    boxBorder: {
      main: "#CCCCCC",
    },
    white: {
      main: "#FFF",
      light: "#FFF",
      dark: "#FFF",
    },
    info: {
      main: "#6699FF",
    },
    navy: {
      main: "#00B6D5",
    },
    danger: {
      main: "#d32f2f",
    },
  },
};

const darkColorScheme = {
  typography: {
    fontFamily: "SF PRO DISPLAY",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
    h1: {
      color: "#FF6600",
    },
    h2: {
      fontSize: "1.5rem",
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#FF6600",
    },
    secondary: {
      main: "#000099",
    },
    textPrimary: {
      main: "#6699FF",
    },
    textSecondary: {
      main: "#00B6D5",
    },
  },
};

const modalBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "80%",
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: 5,
  outline: "none",
  boxShadow: 5,
  p: 4,
};

export { lightColorScheme, darkColorScheme, modalBox };
