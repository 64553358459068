import { useLocation } from "react-router-dom";
import Routers from "../routes/router";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import axios from 'axios'

const Layout = () => {
  const [footerMark, setFooterMark] = useState({});
  const [eventAdmin, setEventAdmin] = useState({})
  const blankLayout = ["/login", "/forgot-password", "/reset-password", "/room-pdf"];
  const eventPadding = ["/event-list", "/profile", "/dashboard", "/guest-survey-form"];
  const location = useLocation();
  const isEvent = eventPadding.includes(location.pathname)


  const getFooterWater = async () => {
    try {
      const response = await axios.get("/client/footer-mark");
      const { setting, eventAdmin } = response.data.payload.data;
      setFooterMark(setting);
      setEventAdmin(eventAdmin)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('jwtToken') && !blankLayout.includes(location.pathname)) {
      getFooterWater();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {blankLayout.includes(location.pathname) ? (
        <>
          <div className={location.pathname === "/room-pdf" ? "" : "loginbackground_img"}></div>
          <Routers />
        </>
      ) : (
        <>
          <div className="background_img"></div>
          <Navbar eventAdmin={eventAdmin}/>
          <Container maxWidth="lg" sx={{py: !isEvent ? 0 : 5}}>
            <Routers />
          </Container>
          <Footer footerMark={footerMark}/>
        </>
      )}
    </>
  );
};

export default Layout;
