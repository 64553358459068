import React from "react";
import Box from "@mui/material/Box";
import Logo from "../assets/images/logo/orgeven_name.svg";
import { Typography } from "@mui/material";
import { getImage } from "../utils/utils";

export default function Footer({ position = "fixed", zIndex = 999, footerMark={} }) {
  return (
    <Box
      bgcolor={footerMark?.footer_watermark?.background_color ?? "primary.main"}
      sx={{
        position: position,
        bottom: 0,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        py: 1,
        zIndex: zIndex,
      }}
    >
      {!footerMark?.footer_watermark?.text && footerMark?.footer_watermark?.banner && (
        <Box
          component="img"
          sx={{
            maxWidth: 100,
          }}
          alt="Logo"
          src={getImage("setting", footerMark?.footer_watermark?.banner_img)}
        />
      )}

      {!footerMark?.footer_watermark?.text && !footerMark?.footer_watermark?.banner && (
        <Box
          component="img"
          sx={{
            maxWidth: 100,
          }}
          alt="Logo"
          src={Logo}
        />
      )}

      {!footerMark?.footer_watermark?.banner && footerMark?.footer_watermark?.text && (
        <Typography color={footerMark?.footer_watermark?.text_color}>
          {footerMark?.footer_watermark?.watermark_label}
        </Typography>
      )}
    </Box>
  );
}
