import { Box, Button, Modal, Stack, TextField, Typography } from "@mui/material";
import { modalBox } from "../plugin/mui/theme";
import { MdOutlineCancel } from "react-icons/md";
import { createRef, useState } from "react";
import axios from "axios";
import { showErrorMessage, showSuccessMessage } from "../utils/utils";
import { FiUploadCloud, FiXCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const FeedbackModel = ({ open, toggle }) => {
  const [feedbackDetail, setFeedbackDetail] = useState({});
  const imgRef = createRef();
  const { t } = useTranslation();
  const handleChange = (name, value) => {
    setFeedbackDetail({ ...feedbackDetail, [name]: value });
  };

  const giveFeedBack = async () => {
    try {
      let formData = new FormData();
      for (const property in feedbackDetail) {
        formData.append(property, feedbackDetail[property]);
      }
      const response = await axios.post("/client/feedback", formData);
      toggle();
      showSuccessMessage(response.data.message);
    } catch (error) {
      showErrorMessage(error.response.data.message);
    }
  };

  return (
    <Modal
      open={open}
      onClose={toggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop={true}
      style={{ backdropFilter: "blur(3px)" }}
    >
      <Box sx={modalBox}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ mb: 3 }}
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h5" fontWeight={600}>
          {t("components.feedback_model.feedback_suggestion")}
          </Typography>
          <MdOutlineCancel size={22} onClick={toggle} className="close_icon" />
        </Stack>

        <TextField
          fullWidth
          size="small"
          margin="dense"
          id="outlined-required"
          label={t("components.feedback_model.title")}
          onChange={(e) => handleChange("title", e.target.value)}
        />
        <TextField
          fullWidth
          size="small"
          margin="dense"
          id="outlined-required"
          multiline
          rows={2}
          label={t("components.feedback_model.description")}
          onChange={(e) => handleChange("description", e.target.value)}
        />
        <Button
          variant="outlined"
          fullWidth
          sx={{
            mt: 3,
            color: "navy.main",
            borderColor: "navy.main",
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 50,
            "&:hover": {
              backgroundColor: "transparent",
              borderColor: "navy.main",
            },
          }}
          onClick={() => imgRef.current.click()}
        >
          <FiUploadCloud style={{ marginRight: 10 }} />{t("components.feedback_model.upload_feedback_image")}
        </Button>
        {feedbackDetail?.picture && (
          <Box sx={{ width: "100%", height: 180, borderRadius: 20, position: "relative", mt: 2 }}>
            <img
              src={feedbackDetail?.picture ? URL.createObjectURL(feedbackDetail?.picture) : ""}
              width={"100%"}
              alt=""
              height={"100%"}
              style={{ borderRadius: 20 }}
              onClick={() => imgRef.current.click()}
            />

            <FiXCircle
              size={25}
              style={{ position: "absolute", top: -10, right: 0, cursor: "pointer" }}
              color="#d32f2f"
              onClick={() => {
                setFeedbackDetail({ ...feedbackDetail, picture: "" });
              }}
            />
          </Box>
        )}

        <input
          type="file"
          ref={imgRef}
          style={{ display: "none" }}
          name="logo"
          onChange={(e) => handleChange("picture", e.target.files[0])}
          accept="image/*"
        />

        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 3,
            color: "#FFF",
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 50,
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={() => giveFeedBack()}
        >
          {t("components.feedback_model.give_feedback")}
        </Button>
      </Box>
    </Modal>
  );
};

export default FeedbackModel;
