import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../assets/images/logo/Orgeven logo 1.svg";
import SmallLogo from "../assets/images/logo/Orgeven-icon--logo.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReviewModal from "../components/ReviewModel";
import FeedbackModel from "../components/FeedbackModel";
import { useSelector } from "react-redux";
import { getImage } from "../utils/utils";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { FiChevronDown } from "react-icons/fi";

function Navbar({ eventAdmin }) {
  const { t, i18n } = useTranslation();
  const [anchorElUser, setAnchorElUser] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const { userData } = useSelector((state) => state?.profileData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeLang, setActiveLang] = useState("")
  const language = [
    { label: "English", value: "en" },
    { label: "Français", value: "fr" },
    { label: "Español", value: "es" },
  ];
  const eventPadding = ["/event-list", "/profile", "/dashboard", "/guest-survey-form"];

  const navigate = useNavigate();
  const location = useLocation();
  const isEvent = eventPadding.includes(location.pathname);

  const toggleReviewModal = () => {
    setOpenModal(!openModal);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLang(lng)
    localStorage.setItem("lang", lng)
  };

  const toggleFeedbackModal = () => {
    setOpenFeedbackModal(!openFeedbackModal);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getClientProfile = async () => {
    try {
      const response = await axios.get("/client/profile");
      setIsReview(response.data?.payload?.data?.is_review);
    } catch (error) {}
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userData");
    window.location.href = "/login";
  };

  useEffect(() => {
    if (localStorage.getItem("lang")){
      i18n.changeLanguage(localStorage.getItem("lang"))
    }
    setActiveLang(localStorage.getItem("lang") || "en")
    getClientProfile();
  }, []);

  return (
    <Container maxWidth="lg">
      <AppBar
        position="static"
        elevation={3}
        sx={{
          px: 2,
          borderBottomLeftRadius: !isEvent ? 0 : 20,
          borderBottomRightRadius: !isEvent ? 0 : 20,
        }}
      >
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 4,
              height: "50px",
              width: "auto",
              cursor: "pointer",
            }}
            alt="Logo"
            onClick={() => navigate("/event-list")}
            src={eventAdmin?.picture ? getImage("event-admin", eventAdmin?.picture) : Logo}
          />

          <Box
            component="img"
            sx={{
              display: { xs: "flex", md: "none" },
              mr: 4,
              height: "50px",
              width: "auto",
              cursor: "pointer",
            }}
            alt="Logo"
            onClick={() => navigate("/event-list")}
            src={eventAdmin?.picture ? getImage("event-admin", eventAdmin?.picture) : SmallLogo}
          />

          <Typography variant="h7" sx={{ display: { xs: "none", md: "block", fontSize: 18 } }}>
            {t("layout.navbar.welcome_back")}{" "}
            <Typography variant="span" color="primary">
              {userData?.name || ""}
            </Typography>
          </Typography>
          <Box sx={{ flexGrow: 0, ml: "auto" }}>
            <Button
              id="fade-button"
              sx={{ mr: 3, color: "secondary.main" }}
              aria-controls={anchorEl ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? "true" : undefined}
              onClick={handleClick}
            >
              <Avatar
                src={require(`../assets/flags/${i18n.language}.svg`)}
                sx={{ height: 25, width: 25, mr: 1 }}
              />
              {language.find((f) => f.value === activeLang)?.label}
              <FiChevronDown size={18} style={{ marginTop: 1 }} />
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              PaperProps={{
                sx: { width: 130, borderRadius: 3 },
              }}
              anchorEl={anchorEl}
              open={anchorEl}
              onClose={handleClose}
            >
              {language?.map((l) => (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    changeLanguage(l?.value);
                  }}
                >
                  <Avatar
                    src={require(`../assets/flags/${l?.value}.svg`)}
                    sx={{ height: 25, width: 25, mr: 1 }}
                  />{" "}
                  {l?.label}
                </MenuItem>
              ))}
            </Menu>
            <Tooltip title="Profile">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={userData?.name} src={getImage("client", userData?.picture)} />
              </IconButton>
            </Tooltip>
            <Menu
              PaperProps={{
                sx: { width: 300, maxHeight: 500, borderRadius: 3 },
              }}
              MenuListProps={{
                // style the menu list
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt={userData?.name}
                  src={getImage("client", userData?.picture)}
                  sx={{ width: 100, height: 100, fontSize: 40 }}
                />
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {userData?.name}
                </Typography>
              </Box>

              <MenuItem onClick={handleCloseUserMenu}>
                <NavLink to="/profile">
                  <Typography textAlign="center">{t("layout.navbar.my_profile")}</Typography>
                </NavLink>
              </MenuItem>
              {!isReview && (
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center" onClick={() => toggleReviewModal()}>
                    {t("layout.navbar.review_to_application")}
                  </Typography>
                </MenuItem>
              )}
              <MenuItem onClick={handleCloseUserMenu}>
                <Typography textAlign="center" onClick={() => toggleFeedbackModal()}>
                  {t("layout.navbar.feedback_suggestion")}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseUserMenu}>
                <Typography textAlign="center" color={"danger.main"} onClick={() => handleLogout()}>
                  {t("layout.navbar.logout")}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <ReviewModal
        open={openModal}
        listCall={() => getClientProfile()}
        toggle={toggleReviewModal}
        user_type={"system"}
        review_type="system"
        title="Review To Application"
      />
      <FeedbackModel open={openFeedbackModal} toggle={toggleFeedbackModal} />
    </Container>
  );
}
export default Navbar;
