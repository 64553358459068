import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { modalBox } from "../plugin/mui/theme";
import { MdOutlineCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";

const DeleteModel = ({ open, toggle, acceptClick, title, message }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={toggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop={true}
      style={{ backdropFilter: "blur(3px)" }}
    >
      <Box sx={modalBox}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ mb: 3 }}
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h5" fontWeight={600}>
            {title ? title : t("components.delete_model.remove_guest")}
          </Typography>
          <MdOutlineCancel size={22} onClick={toggle} className="close_icon" />
        </Stack>
        <Typography variant="h6">
          {message
            ? message
            : t("components.delete_model.remove_guest_msg")}
        </Typography>
        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 3,
            color: "#FFF",
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 50,
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={() => acceptClick()}
        >
          {t("components.delete_model.delete")}
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteModel;
