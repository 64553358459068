import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MdOutlineCancel } from "react-icons/md";
import { modalBox } from "../../../../plugin/mui/theme";
import { useEffect, useState } from "react";
import axios from "axios";
import { showErrorMessage, showSuccessMessage } from "../../../../utils/utils";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const EditTable = ({ table_id, open, toggle, getGuestList, getGuestTable }) => {
  const { t } = useTranslation();

  const [tableDetail, setTableDetail] = useState();
  const [capacity, setCapacity] = useState({});
  const [peopleCapacity, setPeopleCapacity] = useState();

  const handleChange = (name, value) => {
    setTableDetail({ ...tableDetail, [name]: value });
  };

  const onHandleClick = async () => {
    try {
      let chairs = tableDetail?.chairs;
      if (peopleCapacity > tableDetail?.number_of_chair) {
        for (let i = tableDetail?.number_of_chair + 1; i <= peopleCapacity; i++) {
          chairs.push({
            chair_id: `${tableDetail?.table_id}_s${i}`,
            table_id: tableDetail?.table_id,
          });
        }
      }

      if (peopleCapacity < tableDetail?.number_of_chair) {
        const removed_table = Number(tableDetail?.number_of_chair) - Number(peopleCapacity);
        chairs = chairs.sort((a, b) => {
          if (a.guest_id === null && b.guest_id === null) return 0;
          if (a.guest_id === null) return -1;
          if (b.guest_id === null || b.guest_id === undefined) return 1;

          return a.guest_id.localeCompare(b.guest_id);
        });
        for (let i = 0; i < removed_table; i++) {
          chairs.splice(i, 1);
        }
      }

      const response = await axios.put(`/client/event-assets/${table_id}`, {
        table_name: tableDetail?.table_name,
        chairs,
        number_of_chair: peopleCapacity,
      });

      showSuccessMessage(response?.data?.message);
      toggle();
      getGuestList();
      getGuestTable();
    } catch (error) {
      console.log(error);
      showErrorMessage(error.response?.data?.message);
    }
  };

  //   const addAssets = async (requestData) => {
  //     try {
  //       const response = await axios.post("/client/event-assets", requestData);
  //       setTableList([...tableList, response?.data?.payload?.data]);
  //       showSuccessMessage(response.data.message);
  //     } catch (error) {
  //       showErrorMessage(error.response.data.message);
  //     }
  //   };

  //   const onHandleClick = () => {
  //     let Arr = [];
  //     let RequestArr = [];

  //     let table_id = `table_${Date.now()}`;

  //     if (peopleCapacity > 0) {
  //       for (let i = 1; i <= peopleCapacity; i++) {
  //         Arr.push({ chair_id: `${table_id}_s${i}`, guest: {}, table_id: table_id });
  //         RequestArr.push({ chair_id: `${table_id}_s${i}`, table_id: table_id });
  //       }
  //     }

  //     const requestData = {
  //       ...tableDetail,
  //       number_of_chair: peopleCapacity,
  //       table_type: tableType,
  //       table_id: table_id,
  //       chairs: RequestArr,
  //     };

  //     addAssets(requestData);
  //     toggle();
  //   };

  //   useEffect(() => {
  //     if (capacity?.min) {
  //       setPeopleCapacity(capacity?.min);
  //     } else {
  //       setPeopleCapacity(0);
  //     }
  //   }, [capacity]);

  const getTableDetail = async () => {
    try {
      const response = await axios.get(`/client/event-assets/${table_id}`);
      const { number_of_chair, assets } = response.data?.payload?.data;
      setTableDetail(response.data?.payload?.data);
      setPeopleCapacity(number_of_chair);
      setCapacity({ min: assets?.min_person, max: assets?.max_person });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTableDetail();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      open={open}
      onClose={toggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop={true}
      style={{ backdropFilter: "blur(3px)" }}
    >
      <Box sx={modalBox}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ mb: 3 }}
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h5" fontWeight={600}>
            {t("pages.event.steps.room_component.edit_table.edit_table")}
          </Typography>
          <MdOutlineCancel size={22} onClick={() => toggle()} className="close_icon" />
        </Stack>

        {tableDetail?.assets?.picture && (
          <img
            src={require(`../../../../assets/images/tables/${tableDetail?.assets?.picture}.svg`)}
            alt="table"
            width="65"
          />
        )}

        <TextField
          required
          fullWidth
          value={tableDetail?.table_name || ""}
          size="small"
          margin="dense"
          id="outlined-required"
          label={t("pages.event.steps.room_component.edit_table.name")}
          onChange={(e) => handleChange("table_name", e.target.value)}
        />

        {peopleCapacity ? (
          <TextField
            fullWidth
            value={peopleCapacity}
            type="text"
            size="small"
            margin="dense"
            id="outlined-required"
            label={t("pages.event.steps.room_component.edit_table.number_of_chair")}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Stack
                  flexDirection="row"
                  justifyContent="center"
                  sx={{ backgroundColor: "inactive_text.main", borderRadius: 50, width: 100 }}
                >
                  <IconButton
                    aria-label="min"
                    disabled={peopleCapacity === capacity.min}
                    color="primary"
                    onClick={() => setPeopleCapacity((prev) => prev - 1)}
                  >
                    <FiMinus />
                  </IconButton>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <IconButton
                    aria-label="max"
                    disabled={peopleCapacity === capacity.max}
                    color="primary"
                    onClick={() => setPeopleCapacity((prev) => prev + 1)}
                  >
                    <FiPlus />
                  </IconButton>
                </Stack>
              ),
            }}
            // value={loginInfo?.password || ""}
            // onChange={(e) => handleChange("password", e.target.value)}
            // error={error?.password?.error}
            // helperText={error?.password?.message}
          />
        ) : (
          ""
        )}

        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 3,
            color: "#FFF",
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 50,
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={() => onHandleClick()}
        >
          {t("pages.event.steps.room_component.edit_table.edit_table")}
        </Button>
      </Box>
    </Modal>
  );
};

export default EditTable;
