import { BrowserRouter } from "react-router-dom";
import Layout from "./layout/Layout";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Layout />
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
