import { Backdrop } from "@mui/material";
import LOGOGIF from "../assets/images/logo/orgeven.gif";
import { useSelector } from "react-redux";

export const BackDrop = () => {
  const { loading } = useSelector((state) => state.profileData);
  return (
    <Backdrop sx={{ color: "#fff", background: "rgba(255,255,255, 0.8)", zIndex: 9999 }} open={loading}>
      <img src={LOGOGIF} alt="logo" width={120} />
    </Backdrop>
  );
};
