import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    picture: localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))?.picture
      : "",
    name: localStorage.getItem("userData")
      ? `${JSON.parse(localStorage.getItem("userData"))?.first_name} ${
          JSON.parse(localStorage.getItem("userData"))?.last_name
        }`
      : "",
  },
  loading: false
};

export const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    SETUSERDETAIL: (state, action) => {
      state.userData = { ...state, ...action.payload };
    },
    SETLOADING: (state, action) => {
      state.loading = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const { SETUSERDETAIL, SETLOADING } = ProfileSlice.actions;

export default ProfileSlice.reducer;
