import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { Backdrop } from "@mui/material";
import LOGOGIF from "../assets/images/logo/orgeven.gif";
import RoomPDF from "../pages/Event/Steps/RoomPDF";

const Home = lazy(() => import("../pages/Dashboard/index"));
const Login = lazy(() => import("../pages/Login/index"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword/index"));
const ResetPassword = lazy(() => import("../pages/ResetPassword/index"));
const Event = lazy(() => import("../pages/Event/index"));
const EventList = lazy(() => import("../pages/EventList/index"));
const Profile = lazy(() => import("../pages/Profile/index"));
const GuestSurveyForm = lazy(() => import("../pages/GuestSurveyForm/index"));
const RoomPDf = lazy(() => import("../pages/Event/Steps/RoomPDF"))

const token = localStorage.getItem("jwtToken") || false;

const BackDrop = ({ isActive }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", background: "rgba(255,255,255, 0.8)" }}
      open={isActive}
    >
      <img src={LOGOGIF} alt="logo" width={120} />
    </Backdrop>
  );
};

const authRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/room-pdf",
    element: <RoomPDF />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
];

const pageRoutes = [
  {
    path: "/dashboard",
    element: <Home />,
  },
  {
    path: "/event-list",
    element: <EventList />,
  },
  {
    path: "/event/:step",
    element: <Event />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/guest-survey-form",
    element: <GuestSurveyForm />,
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" replace={true} />,
  },
];

const Routers = () => {
  return (
    <Routes>
      {authRoutes.map((auth, index) => (
        <Route
          key={index}
          exact
          path={auth?.path}
          element={
            <Suspense fallback={<BackDrop isActive={true} />}>
              {auth?.element}
            </Suspense>
          }
        />
      ))}

      <Route element={<ProtectedRoute user={token} />}>
        {pageRoutes.map((page, index) => (
          <Route
            key={index}
            exact
            path={page?.path}
            element={
              <Suspense fallback={<BackDrop isActive={true} />}>
                {page?.element}
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route path="/" element={<Navigate to="/login" replace={true} />} />
    </Routes>
  );
};

export default Routers;
