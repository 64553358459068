import { Avatar, Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { getImage, showErrorMessage, showSuccessMessage } from "../../../../utils/utils";
import DeleteModel from "../../../../components/DeleteModal";
import EditTable from "./EditTableModal";
import { FiEdit2, FiRotateCw, FiTrash2 } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const CreateRectangleTable = ({
  _id,
  table_name,
  x,
  y,
  rotation_degree,
  chair,
  table_id,
  tableList,
  setTableList,
  tables,
  handleDragStart,
  getGuestTable,
  getGuestList,
  updateTable
}) => {
  const { t } = useTranslation();

  const [position, setPosition] = useState({ x, y });
  const [rotation, setRotation] = useState(rotation_degree);
  const [showAction, setShowAction] = useState(false);
  const [onMouse, setOnMouse] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const totalChairs = Number(chair);
  const topChairs = Math.floor(totalChairs / 2);

  const topChair = tables.slice(0, topChairs);
  const bottomChair = tables.slice(topChairs, totalChairs);

  const toggleEditModal = () => setOpenEditModal(!openEditModal);

  const toggleDeleteModal = () => setOpenDeleteModal(!openDeleteModal);

  const updateTableAssets = async (requestData, fromTable = _id) => {
    try {
      await axios.put(`/client/event-assets/${fromTable}`, requestData);
    } catch (error) {
      showErrorMessage(error.response.data?.message);
    }
  };

  const deleteTable = async () => {
    try {
      const response = await axios.delete(`/client/event-assets/${_id}`);
      showSuccessMessage(response.data?.message);
      toggleDeleteModal();
      getGuestTable();
      getGuestList();
    } catch (error) {
      showErrorMessage(error.response.data?.message);
    }
  };

  // drop the guest in table
  function handleDrop(e, id) {
    e.preventDefault();
    const data = e.dataTransfer.getData("text/plain");
    const from_table = e.dataTransfer.getData("text/table");
    const GuestDetail = data ? JSON.parse(data) : {};

    // set table and table list
    let Tables = tables;
    let TableLists = tableList;

    //check for is guest is already in other table or not and change the position for other table
    const isGuestOfFromTable = tableList.find((f) => f?.table_id === from_table);

    const isGuestExist =
      isGuestOfFromTable &&
      isGuestOfFromTable.chairs.find((f) => f?.guest?._id === GuestDetail?._id);

    if (isGuestExist && Object.keys(isGuestExist?.guest).length > 0) {
      const FromTables = isGuestOfFromTable.chairs.map((space) =>
        space.chair_id === isGuestExist?.chair_id ? { ...space, guest: {}, guest_id: null } : space
      );

      TableLists = tableList.map((table) =>
        table.table_id === from_table ? { ...table, chairs: FromTables } : table
      );

      const tableData = TableLists.find((f) => f.table_id === from_table);

      updateTableAssets(tableData, tableData?._id);
    }

    // check for is guest on same table and change position on same table
    const isAlreadyGuest = tables.find((f) => f?.guest?._id === GuestDetail?._id);

    if (isAlreadyGuest && Object.keys(isAlreadyGuest?.guest).length > 0) {
      Tables = tables.map((space) =>
        space.chair_id === isAlreadyGuest?.chair_id
          ? { ...space, guest: {}, guest_id: null }
          : space
      );

      TableLists = tableList.map((table) =>
        table.table_id === table_id ? { ...table, chairs: Tables } : table
      );
    }

    // update the table list array according to guest position
    const updatedTables = Tables.map((space) =>
      space.chair_id === id
        ? { ...space, guest: GuestDetail, guest_id: GuestDetail?.guest_id, table_id: table_id }
        : space
    );

    const updatedTableList = TableLists.map((table) =>
      table.table_id === table_id ? { ...table, chairs: updatedTables } : table
    );

    const tableData = updatedTableList.find((f) => f.table_id === table_id);

    updateTableAssets(tableData);
    updateTable(GuestDetail?.guest_id, tableData?._id)
    setTableList(updatedTableList);
  }

  //drag & drop the table on surface
  const dragMouseDown = (e) => {
    e.preventDefault();
    const initialPos = {
      x: e.clientX,
      y: e.clientY,
    };

    const handleMouseMove = (event) => {
      setOnMouse(true);
      const dx = event.clientX - initialPos.x;
      const dy = event.clientY - initialPos.y;

      setPosition((prevPosition) => ({
        x: prevPosition.x + dx >= 0 ? prevPosition.x + dx : prevPosition.x,
        y: prevPosition.y + dy >= 0 ? prevPosition.y + dy : prevPosition.y,
      }));

      initialPos.x = event.clientX;
      initialPos.y = event.clientY;
    };

    const handleMouseUp = () => {
      setOnMouse(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      // Update parent component's state with the new position
      const updatedTableList = tableList.map((table) =>
        table.table_id === table_id ? { ...table, x: position.x, y: position.y } : table
      );

      setTableList(updatedTableList);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const rotateTable = () => {
    setRotation((prev) => prev === 360 ? 0 : prev + 90);
    const updatedTableList = tableList.map((table) =>
      table.table_id === table_id
        ? { ...table, rotation_degree: table.rotation_degree + 90 }
        : table
    );
    updateTableAssets({ rotation_degree: rotation === 360 ? 0 : rotation + 90});
    setTableList(updatedTableList);
  };

  useEffect(() => {
    if (!onMouse) {
      updateTableAssets({ x: position?.x, y: position?.y });
    }
    // eslint-disable-next-line
  }, [onMouse]);

  return (
    <Box
      sx={{
        position: "absolute",
        left: position.x,
        top: position.y,
        transform: `rotate(${rotation}deg)`,
      }}
      key={table_id}
      onMouseOver={() => setShowAction(true)}
      onMouseLeave={() => setShowAction(false)}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={2}
        sx={{ mb: -1 }}
      >
        {topChair.length > 0 &&
          topChair.map((data, index) => (
            <Avatar
              draggable="true"
              onDragStart={(e) => handleDragStart(e, data?.guest, table_id)}
              src={data?.guest?.picture ? getImage("guest", data?.guest?.picture) : ""}
              key={index}
              sx={{
                width: 33,
                height: 33,
                zIndex: 10,
                border: 2,
                borderColor:
                  data?.guest && Object.keys(data?.guest).length > 0
                    ? "primary.main"
                    : "table_text.main",
                background: data?.guest && Object.keys(data?.guest).length > 0 ? "" : "#FFF",
              }}
              onDrop={(e) => handleDrop(e, data?.chair_id)}
              onDragOver={(e) => e.preventDefault()}
            />
          ))}
      </Stack>
      <Stack flexDirection="row">
        <Box
          sx={{
            boxSizing: "border-box",
            border: 2,
            borderColor: "table_text.main",
            position: "relative",
            py: 2,
            zIndex: 1,
            textAlign: "center",
            background: "#FFF",
            width: "100%",
            cursor: "move",
          }}
          onMouseDown={dragMouseDown}
        >
          <Typography color="table_text.main">{table_name}</Typography>
        </Box>
      </Stack>
      <Stack flexDirection="row" gap={2} sx={{ mt: -1 }}>
        {bottomChair.length > 0 &&
          bottomChair.map((data, index) => (
            <Avatar
              draggable="true"
              onDragStart={(e) => handleDragStart(e, data?.guest, table_id)}
              src={data?.guest?.picture ? getImage("guest", data?.guest?.picture) : ""}
              key={index}
              sx={{
                width: 33,
                height: 33,
                zIndex: 10,
                border: 2,
                borderColor:
                  data?.guest && Object.keys(data?.guest).length > 0
                    ? "primary.main"
                    : "table_text.main",
                background: data?.guest && Object.keys(data?.guest).length > 0 ? "" : "#FFF",
              }}
              onDrop={(e) => handleDrop(e, data?.chair_id)}
              onDragOver={(e) => e.preventDefault()}
            />
          ))}
      </Stack>

      {showAction && (
        <Stack flexDirection="column" position="absolute" right="-42px" top="-25px" gap={2}>
          <Box
            sx={{
              backgroundColor: "inactive_text.main",
              p: 1,
              width: 40,
              height: 40,
              borderRadius: 50,
            }}
          >
            <FiEdit2
              color="#FFF"
              size={24}
              onClick={() => {
                toggleEditModal();
              }}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "inactive_text.main",
              p: 1,
              width: 40,
              height: 40,
              borderRadius: 50,
            }}
          >
            <FiTrash2
              color="#FFF"
              size={24}
              onClick={() => {
                toggleDeleteModal();
              }}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "inactive_text.main",
              p: 1,
              width: 40,
              height: 40,
              borderRadius: 50,
            }}
          >
            <FiRotateCw
              color="#FFF"
              size={24}
              onClick={() => {
                rotateTable();
              }}
            />
          </Box>
        </Stack>
      )}

      <DeleteModel
        open={openDeleteModal}
        toggle={toggleDeleteModal}
        message={t("pages.event.steps.room_component.delete_table.remove_table_msg")}
        title={t("pages.event.steps.room_component.delete_table.remove_table")}
        acceptClick={deleteTable}
      />

      {openEditModal && (
        <EditTable
          table_id={_id}
          open={openEditModal}
          toggle={toggleEditModal}
          getGuestList={getGuestList}
          getGuestTable={getGuestTable}
        />
      )}
    </Box>
  );
};

export default CreateRectangleTable;
