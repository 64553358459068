import toast from "react-hot-toast";
import Wedding from "../assets/images/Wedding.svg";
import Birthday from "../assets/images/Birthday.svg";
import Engagement from "../assets/images/Engagement.svg";
import Briefcase from "../assets/images/Briefcase.svg";
import Meeting from "../assets/images/Meeting.svg";
import Party from "../assets/images/Party.svg";
import Food from "../assets/images/Food.svg";
import Music from "../assets/images/Music.svg";
import Corporateparty from "../assets/images/Corporateparty.svg";
import Other from '../assets/images/Others.svg'

export const showSuccessMessage = (message) => {
  toast.success(message, { position: "top-right" });
};

export const showErrorMessage = (message) => {
  toast.error(message, { position: "top-right" });
};

export const getImage = (folderName, image) => {
  return `${process.env.REACT_APP_BASEURL}/${folderName}/${image}`;
};

export const setEventID = (navigate, route, event_id) => {
  navigate(route, { state: { event_id } });
  localStorage.setItem("event_id", event_id);
};

export const getEventID = (location) => {
  const event_id = location?.state?.event_id
    ? location?.state?.event_id
    : localStorage.getItem("event_id") || "";
  return event_id;
};

export const EventThemeIcons = [
  {
    name: "wedding",
    image: Wedding,
  },
  {
    name: "birthday",
    image: Birthday,
  },
  {
    name: "engagement",
    image: Engagement,
  },
  {
    name: "briefcase",
    image: Briefcase,
  },
  {
    name: "meeting",
    image: Meeting,
  },
  {
    name: "party",
    image: Party,
  },
  {
    name: "food",
    image: Food,
  },
  {
    name: "music",
    image: Music,
  },
  {
    name: "corporateparty",
    image: Corporateparty,
  },
];


export const getEventThemeIcon = (iconName) => {
  return EventThemeIcons.find(f => f.name === iconName)?.image || Other
}

export const ageOptions = [
  {
    label: "Adult",
    value: "adult",
  },
  {
    label: "Child",
    value: "child",
  },
  {
    label: "Baby",
    value: "baby",
  },
];

export const sexOptions = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

