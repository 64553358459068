import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  getImage,
  showErrorMessage,
  showSuccessMessage,
} from "../../../../utils/utils";
import axios from "axios";
import { FiEdit2,  FiTrash2 } from "react-icons/fi";
import DeleteModel from "../../../../components/DeleteModal";
import EditTable from "./EditTableModal";
import { useTranslation } from "react-i18next";

const CreateRoundTable = ({
  _id,
  table_name,
  x,
  y,
  chair,
  table_id,
  tableList,
  setTableList,
  tables,
  handleDragStart,
  getGuestTable,
  getGuestList,
  updateTable,
}) => {
  const { t } = useTranslation();

  const [position, setPosition] = useState({ x, y });
  const [showAction, setShowAction] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [onMouse, setOnMouse] = useState(true);
  const totalChairs = Number(chair);
  const roundHeightWidth = (totalChairs * 140) / 6;
  const chairDegree = 360 / totalChairs;
  const translate = (totalChairs * 60) / 6 - 6;

  const toggleEditModal = () => setOpenEditModal(!openEditModal);

  const toggleDeleteModal = () => setOpenDeleteModal(!openDeleteModal);

  const updateTableAssets = async (requestData, fromTable = _id) => {
    try {
      await axios.put(`/client/event-assets/${fromTable}`, requestData);
    } catch (error) {
      showErrorMessage(error.response.data?.message);
    }
  };

  //handle guest drop
  function handleDrop(e, id) {
    //getting dragged data
    e.preventDefault();
    const data = e.dataTransfer.getData("text/plain");
    const from_table = e.dataTransfer.getData("text/table");
    const GuestDetail = data ? JSON.parse(data) : {};

    // set table and table list
    let Tables = tables;
    let TableLists = tableList;

    //check for is guest is already in other table or not and change the position for other table
    const isGuestOfFromTable = tableList.find(
      (f) => f?.table_id === from_table
    );

    const isGuestExist =
      isGuestOfFromTable &&
      isGuestOfFromTable.chairs.find((f) => f?.guest?._id === GuestDetail?._id);

    if (isGuestExist && Object.keys(isGuestExist?.guest).length > 0) {
      const FromTables = isGuestOfFromTable.chairs.map((space) =>
        space.chair_id === isGuestExist?.chair_id
          ? { ...space, guest: {}, guest_id: null }
          : space
      );

      TableLists = tableList.map((table) =>
        table.table_id === from_table ? { ...table, chairs: FromTables } : table
      );

      const tableData = TableLists.find((f) => f.table_id === from_table);

      updateTableAssets(tableData, tableData?._id);
    }

    // check for is guest on same table and change position on same table
    const isAlreadyGuest = tables.find(
      (f) => f?.guest?._id === GuestDetail?._id
    );

    if (isAlreadyGuest && Object.keys(isAlreadyGuest?.guest).length > 0) {
      Tables = tables.map((space) =>
        space.chair_id === isAlreadyGuest?.chair_id
          ? { ...space, guest: {}, guest_id: null }
          : space
      );

      TableLists = tableList.map((table) =>
        table.table_id === table_id ? { ...table, chairs: Tables } : table
      );
    }

    // update the table list array according to guest position
    const updatedTables = Tables.map((space) =>
      space.chair_id === id
        ? {
            ...space,
            guest: GuestDetail,
            guest_id: GuestDetail?.guest_id,
            table_id: table_id,
          }
        : space
    );

    const updatedTableList = TableLists.map((table) =>
      table.table_id === table_id ? { ...table, chairs: updatedTables } : table
    );

    const tableData = updatedTableList.find((f) => f.table_id === table_id);

    updateTableAssets(tableData);
    updateTable(GuestDetail?.guest_id, tableData?._id);
    setTableList(updatedTableList);
  }

  // drag & drop the table on surface
  const dragMouseDown = (e) => {
    e.preventDefault();
    const initialPos = {
      x: e.clientX,
      y: e.clientY,
    };

    const handleMouseMove = (event) => {
      setOnMouse(true);
      const dx = event.clientX - initialPos.x;
      const dy = event.clientY - initialPos.y;

      setPosition((prevPosition) => ({
        x: prevPosition.x + dx >= 0 ? prevPosition.x + dx : prevPosition.x,
        y: prevPosition.y + dy >= 0 ? prevPosition.y + dy : prevPosition.y,
      }));

      initialPos.x = event.clientX;
      initialPos.y = event.clientY;
    };

    const handleMouseUp = () => {
      setOnMouse(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      // Update parent component's state with the new position
      const updatedTableList = tableList.map((table) =>
        table.table_id === table_id
          ? { ...table, x: position.x, y: position.y }
          : table
      );

      setTableList(updatedTableList);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const deleteTable = async () => {
    try {
      const response = await axios.delete(`/client/event-assets/${_id}`);
      showSuccessMessage(response.data?.message);
      toggleDeleteModal();
      getGuestTable();
      getGuestList();
    } catch (error) {
      showErrorMessage(error.response.data?.message);
    }
  };

  useEffect(() => {
    if (!onMouse) {
      updateTableAssets({ x: position?.x, y: position?.y });
    }
    // eslint-disable-next-line
  }, [onMouse]);

  return (
    <Box
      key={table_id}
      sx={{
        position: "absolute",
        left: position.x,
        top: position.y,
        width: roundHeightWidth,
        height: roundHeightWidth,
      }}
      onMouseOver={() => setShowAction(true)}
      onMouseLeave={() => setShowAction(false)}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "#FFF",
          boxSizing: "border-box",
          border: 2,
          borderColor: "table_text.main",
          borderRadius: "50%",
          height: "78%",
          width: "78%",
          cursor: "move",
        }}
        onMouseDown={dragMouseDown}
      >
        <Typography
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          color="table_text.main"
        >
          {table_name ? table_name : ""}
        </Typography>
      </Box>
      {totalChairs > 0 &&
        tables.map((data, index) => {
          return (
            <Avatar
              draggable="true"
              onDragStart={(e) => handleDragStart(e, data?.guest, table_id)}
              key={index}
              src={
                data?.guest?.picture
                  ? getImage("guest", data?.guest?.picture)
                  : ""
              }
              sx={{
                position: "absolute",
                width: 33,
                height: 33,
                top: "calc(50% - 17.5px)",
                left: "calc(50% - 17.5px)",
                border: 2,
                borderColor:
                  data?.guest && Object.keys(data?.guest).length > 0
                    ? "primary.main"
                    : "table_text.main",
                background:
                  data?.guest && Object.keys(data?.guest).length > 0
                    ? ""
                    : "#FFF",
                transform: `rotate(${
                  chairDegree * (index + 1)
                }deg) translate(${translate}px) rotate(-${
                  chairDegree * (index + 1)
                }deg)`,
              }}
              onDrop={(e) => handleDrop(e, data?.chair_id)}
              onDragOver={(e) => e.preventDefault()}
            />
          );
        })}
      {showAction && (
        <Stack
          flexDirection="column"
          sx={{ height: roundHeightWidth }}
          position="absolute"
          right="-40px"
          justifyContent="center"
          gap={2}
          alignItems="center"
        >
          <Box
            sx={{
              backgroundColor: "inactive_text.main",
              p: 1,
              width: 40,
              height: 40,
              borderRadius: 50,
            }}
          >
            <FiEdit2
              color="#FFF"
              size={24}
              onClick={() => {
                toggleEditModal();
              }}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "inactive_text.main",
              p: 1,
              width: 40,
              height: 40,
              borderRadius: 50,
            }}
          >
            <FiTrash2
              color="#FFF"
              size={24}
              onClick={() => {
                toggleDeleteModal();
              }}
            />
          </Box>
        </Stack>
      )}

      <DeleteModel
        open={openDeleteModal}
        toggle={toggleDeleteModal}
        message={t(
          "pages.event.steps.room_component.delete_table.remove_table_msg"
        )}
        title={t("pages.event.steps.room_component.delete_table.remove_table")}
        acceptClick={deleteTable}
      />

      {openEditModal && (
        <EditTable
          table_id={_id}
          open={openEditModal}
          toggle={toggleEditModal}
          getGuestList={getGuestList}
          getGuestTable={getGuestTable}
        />
      )}
    </Box>
  );
};

export default CreateRoundTable;
