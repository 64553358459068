import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { showErrorMessage, showSuccessMessage } from "../../../../utils/utils";
import { FiEdit2, FiRotateCw, FiTrash2 } from "react-icons/fi";
import DeleteModel from "../../../../components/DeleteModal";
import EditTable from "./EditTableModal";
import { useTranslation } from "react-i18next";

const CreateStageRectangle = ({
  _id,
  table_name,
  x,
  y,
  rotation_degree,
  table_id,
  tableList,
  setTableList,
  getGuestTable,
  getGuestList,
}) => {
  const { t } = useTranslation();

  const [position, setPosition] = useState({ x, y });
  const [rotation, setRotation] = useState(rotation_degree);
  const [onMouse, setOnMouse] = useState(true);
  const [showAction, setShowAction] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const toggleEditModal = () => setOpenEditModal(!openEditModal);

  const toggleDeleteModal = () => setOpenDeleteModal(!openDeleteModal);

  const updateTableAssets = async (requestData, fromTable = _id) => {
    try {
      await axios.put(`/client/event-assets/${fromTable}`, requestData);
    } catch (error) {
      showErrorMessage(error.response.data?.message);
    }
  };

  const deleteTable = async () => {
    try {
      const response = await axios.delete(`/client/event-assets/${_id}`);
      showSuccessMessage(response.data?.message);
      toggleDeleteModal();
      getGuestTable();
      getGuestList();
    } catch (error) {
      showErrorMessage(error.response.data?.message);
    }
  };

  //drag & drop the table on surface
  const dragMouseDown = (e) => {
    e.preventDefault();
    const initialPos = {
      x: e.clientX,
      y: e.clientY,
    };

    const handleMouseMove = (event) => {
      setOnMouse(true);
      const dx = event.clientX - initialPos.x;
      const dy = event.clientY - initialPos.y;

      setPosition((prevPosition) => ({
        x: prevPosition.x + dx >= 0 ? prevPosition.x + dx : prevPosition.x,
        y: prevPosition.y + dy >= 0 ? prevPosition.y + dy : prevPosition.y,
      }));

      initialPos.x = event.clientX;
      initialPos.y = event.clientY;
    };

    const handleMouseUp = () => {
      setOnMouse(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      // Update parent component's state with the new position
      const updatedTableList = tableList.map((table) =>
        table.table_id === table_id ? { ...table, x: position.x, y: position.y } : table
      );

      setTableList(updatedTableList);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const rotateTable = () => {
    setRotation((prev) => prev === 360 ? 0 : prev + 90);
    const updatedTableList = tableList.map((table) =>
      table.table_id === table_id
        ? { ...table, rotation_degree: table.rotation_degree + 90 }
        : table
    );
    updateTableAssets({ rotation_degree: rotation === 360 ? 0 : rotation + 90});
    setTableList(updatedTableList);
  };

  useEffect(() => {
    if (!onMouse) {
      updateTableAssets({ x: position?.x, y: position?.y });
    }
    // eslint-disable-next-line
  }, [onMouse]);

  return (
    <Box
      sx={{
        position: "absolute",
        left: position.x,
        top: position.y,
        transform: `rotate(${rotation}deg)`,
      }}
      key={table_id}
      onMouseOver={() => setShowAction(true)}
      onMouseLeave={() => setShowAction(false)}
    >
      <Stack flexDirection="row">
        <Box
          sx={{
            boxSizing: "border-box",
            border: 2,
            borderColor: "table_text.main",
            position: "relative",
            py: 2,
            zIndex: 1,
            textAlign: "center",
            background: "#FFF",
            width: 200,
            cursor: "move",
          }}
          onMouseDown={dragMouseDown}
        >
          <Typography color="table_text.main">{table_name}</Typography>
        </Box>
      </Stack>

      {showAction && (
        <Stack flexDirection="column" position="absolute" right="-42px" top="-40px" gap={2}>
          <Box
            sx={{
              backgroundColor: "inactive_text.main",
              p: 1,
              width: 40,
              height: 40,
              borderRadius: 50,
            }}
          >
            <FiEdit2
              color="#FFF"
              size={24}
              onClick={() => {
                toggleEditModal();
              }}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "inactive_text.main",
              p: 1,
              width: 40,
              height: 40,
              borderRadius: 50,
            }}
          >
            <FiTrash2
              color="#FFF"
              size={24}
              onClick={() => {
                toggleDeleteModal();
              }}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "inactive_text.main",
              p: 1,
              width: 40,
              height: 40,
              borderRadius: 50,
            }}
          >
            <FiRotateCw
              color="#FFF"
              size={24}
              onClick={() => {
                rotateTable();
              }}
            />
          </Box>
        </Stack>
      )}

      <DeleteModel
        open={openDeleteModal}
        toggle={toggleDeleteModal}
        message={t("pages.event.steps.room_component.delete_table.remove_table_msg")}
        title={t("pages.event.steps.room_component.delete_table.remove_table")}
        acceptClick={deleteTable}
      />

      {openEditModal && (
        <EditTable
          table_id={_id}
          open={openEditModal}
          toggle={toggleEditModal}
          getGuestList={getGuestList}
          getGuestTable={getGuestTable}
        />
      )}
    </Box>
  );
};

export default CreateStageRectangle;
