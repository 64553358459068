import { configureStore, combineReducers } from "@reduxjs/toolkit";

import PROFILEREDUCER from "./profile/index";

const reducer = combineReducers({
  profileData: PROFILEREDUCER,
});

const store = configureStore({
  reducer,
});

export default store;
