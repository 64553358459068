import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import CreateSquareTable from "./RoomComponent/TableSquare";
import CreateRoundTable from "./RoomComponent/TableRound";
import axios from "axios";
import CreateRectangleTable from "./RoomComponent/TableRectangle";
import CreateTableTop from "./RoomComponent/TableTop";
import CreateStageRectangle from "./RoomComponent/StageRectangle";
import CreateStageRound from "./RoomComponent/StageRound";
import CreateStageSquare from "./RoomComponent/StageSquare";
import CreateChair from "./RoomComponent/Chair";
import { useSearchParams } from "react-router-dom";

export default function RoomPDF() {
  const [tableList, setTableList] = useState([]);
  const [searchParams] = useSearchParams();

  // get tables token
  const getGuestTableToken = async () => {
    if (searchParams.get("token")) {
      try {
        let config = {
          headers: {
            Authorization: `Bearer ${searchParams.get("token")}`, // Uncomment when user authorization works
            Accept: "application/json",
          },
        };
        const response = await axios.get(
          `/client/event-assets?event_id=${searchParams.get("event_id")}`,
          config
        );
        setTableList(response.data?.payload?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // load table from type
  const loadTableComponent = (table_type, props) => {
    switch (table_type) {
      case "table_square":
        return <CreateSquareTable {...props} />;
      case "table_round":
        return <CreateRoundTable {...props} />;
      case "table_rectangle":
        return <CreateRectangleTable {...props} />;
      case "table_rectangle_top":
        return <CreateTableTop {...props} />;
      case "table_stage_rectangle":
        return <CreateStageRectangle {...props} />;
      case "table_stage_round":
        return <CreateStageRound {...props} />;
      case "table_stage_square":
        return <CreateStageSquare {...props} />;
      case "table_chair":
        return <CreateChair {...props} />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (searchParams.get("token")) {
      getGuestTableToken();
    }
  }, [searchParams]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {tableList.length > 0 &&
        tableList.map((table) => {
          const commonProps = {
            _id: table?._id,
            table_name: table?.table_name,
            table_id: table?.table_id,
            x: table?.x,
            y: table?.y,
            rotation_degree: table?.rotation_degree,
            chair: table?.number_of_chair,
            tables: table?.chairs,
          };

          return loadTableComponent(table?.table_type, {
            ...commonProps,
            tableList,
            setTableList,
          });
        })}
    </Box>
  );
}
