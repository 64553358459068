import { Box, Button, Modal, Stack, TextField, Typography } from "@mui/material";
import { modalBox } from "../plugin/mui/theme";
import { MdOutlineCancel } from "react-icons/md";
import { Rating } from "react-simple-star-rating";
import { useState } from "react";
import axios from "axios";
import { showErrorMessage, showSuccessMessage } from "../utils/utils";
import { useTranslation } from "react-i18next";

const ReviewModal = ({ open, toggle, event_id, event_admin, user_type, review_type, title, listCall }) => {
  const [rating, setRating] = useState(0);
  const [reviewDetail, setReviewDetail] = useState({});
  const { t } = useTranslation();

  const changeRating = (newRating) => {
    setRating(newRating);
  };

  const handleChange = (name, value) => {
    setReviewDetail({ ...reviewDetail, [name]: value });
  };

  const giveFeedBack = async () => {
    try {
      const requestData = {
        user_type: user_type,
        review_user_type: "client",
        review_type: review_type,
        flexibility_rating: rating,
        title: reviewDetail?.title,
        comment: reviewDetail?.comment,
      };

      if (event_admin) {
        requestData.name = event_admin;
      }

      if (event_id) {
        requestData.event_id = event_id;
      }

      const response = await axios.post("/client/review", requestData);
      toggle();
      listCall();
      showSuccessMessage(response.data.message);
    } catch (error) {
      showErrorMessage(error.response.data.message);
    }
  };

  return (
    <Modal
      open={open}
      onClose={toggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop={true}
      style={{ backdropFilter: "blur(3px)" }}
    >
      <Box sx={modalBox}>
        <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 3 }} alignItems="center">
          <Typography id="modal-modal-title" variant="h5" fontWeight={600}>
            {title ? title : t("components.review_modal.review_to_event_admin")}
          </Typography>
          <MdOutlineCancel size={22} onClick={toggle} className="close_icon"/>
        </Stack>
        <Rating onClick={changeRating} initialValue={rating} fillColor="#FF6600" />
        <TextField
          fullWidth
          size="small"
          margin="dense"
          id="outlined-required"
          label={t("components.review_modal.title")}
          onChange={(e) => handleChange("title", e.target.value)}
        />
        <TextField
          fullWidth
          size="small"
          margin="dense"
          id="outlined-required"
          multiline
          rows={2}
          label={t("components.review_modal.message")}
          onChange={(e) => handleChange("comment", e.target.value)}
        />

        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 3,
            color: "#FFF",
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 50,
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={() => giveFeedBack()}
        >
          {t("components.review_modal.give_review")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ReviewModal;
